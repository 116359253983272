/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS231D
 * 화면 설명: 청약 파트 설계청약현황
 *            모바일청약 완료건
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container v-if="!isNodata" alignV="start" componentid="" direction="column" class="status-list type-expand">
      <mo-list :list-data="tableData1">
        <template #list-item="{item, index}">      
          <mo-list-item class=""> 
            <msp-expand btn-area-first title-first expandable :preventScrollWhenExpand="true" :ref="'expItem_'+index" class="mo-list-expand w100" btn-area-class="fexTy2 firstdiv-full fexJsCtTy1"> 
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title pt6">
                    <span class="name txtSkip fs19rem fwb">{{item.mnPrdtNm}}</span>
                  </div>
                  <div class="list-item__contents__info mt6">
                    <span><span class="fs14rem mr6 crTy-bk7">피보험자</span><span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1 underline" @click.stop="fn_PopupCustNmUiCard(item.insrdChnlCustId,'주피보험자')">{{item.insrdNm === ' ' ? item.mnContrNm : item.insrdNm}}</span></span><span>
                    <em class="em_normal">|</em>
                    <span class="fs14rem mr8 crTy-bk7">청약일</span><span class="crTy-bk1 fs16rem ls--1">{{item.elstPblYmd === null ? '-' : item.elstPblYmd}}</span></span>
                  </div>
                  <div class="list-item__contents__info mt6 mb10">
                    <span><span class="fs14rem mr8 crTy-bk7">보험료</span><span class="crTy-bk1 fs16rem fwb crTy-blue3">{{$bizUtil.numberWithCommasCurr(item.contPrm, item.crncyCd, 'Y', 'Y', 3)}}</span></span>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow down mt10"></mo-button>
              </template>
              <template #content>
                <div class="list-item-detail gray_style bdnone margin--full pt20 pb20">
                  <ur-box-container class="con--padding ">
                    <div class="contents-row">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">계약자</span><span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1 underline"  @click.stop="fn_PopupCustNmUiCard(item.mobslChnlCustId,'계약자')">{{item.mnContrNm}}</span>
                    </div>
                    <div class="contents-row mt4">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">영수증번호</span><span class="fs16rem">{{item.vuchId}}</span>
                    </div>
                    <div class="contents-row">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">종피보험자</span><span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1">{{item.aisdNm}}</span>
                    </div>
                    <div class="contents-row mt4">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">교부방식</span><span class="crTy-bk1 fs16rem ls--1">{{item.fstIsuMthScNm}}</span>
                    </div>
                  </ur-box-container>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
            <div class="ns-btn-relative-area mt0">
              <div class="ns-btn-relative-s">
                <div class="relative-div mb5 fex-rw">
                  <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" @click="fn_RTransOpenPopup(item)">계약서류</mo-button>

                  <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-if="item.ofrMmgNm == '접수/출금'" @click="fn_MovAcpnDfra(item)">{{item.ofrMmgNm}}</mo-button>
                  <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-else-if="item.ofrMmgNm === '접수불가'" @click="fn_PopupAcpnDalw(item.scnAcpnDalwRsnCntnt)">{{item.ofrMmgNm}}</mo-button>
                  <!-- <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-else>{{item.ofrMmgNm}}</mo-button> -->

                  <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-if="item.prgPhseNm === '재처리'" @click="fn_PopupRtryTrt(item)">{{item.prgPhseNm}}</mo-button>
                  <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-else-if="item.prgPhseNm === '출금완료'" disabled>{{item.prgPhseNm}}</mo-button>
                  <!-- <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-else disabled>{{item.prgPhseNm}}</mo-button> -->
                </div>
              </div>
            </div>
          </ur-box-container>
        </template>
      </mo-list>
    </ur-box-container>

    <!-- 완료건 NoData 영역 ur-box-containe  -->        
    <PSNodataMesgBoxForList v-if="isNodata" ref="nodataMesgBox" :mesgText="mesgText"/>        
    <!-- / 완료건 NoData 영역 ur-box-containe -->   

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import MSPPS233P from '@/ui/ps/MSPPS233P'
import MSPPS234P from '@/ui/ps/MSPPS234P'
import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'


export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS231D', 
  screenId: 'MSPPS231D',

  components: {
    'PSNodataMesgBoxForList' : PSNodataMesgBoxForList , // mo-list 전용 데이터 없음 박스
    'MSPPS233P' : MSPPS233P ,// 모바일청약 재처리 팝업
    'MSPPS234P' : MSPPS234P  // 설계청약 계약서류발송
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    slctTabIdx : {type:Number, default:1}, // 부모 선택한 현재 탭 인덱스
    srchOptData : {type:Object, default: null} // 부모 검색 상태
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      isInitSrvcCall: false,       // 최초 서비스 호출 한적 있는지 여부
      PSConstants   : PSConstants, // 청약 상수
      mesgText      : '',          // 데이터 없음 표시 메세지
      slctItamData : null,        // 선택된 item Row Object Data  
      //rsltCnt       : 0,          // 출력리스트 갯수
      isNodata      : false,      // 데이터 없음 Flag      
      //rowPopupData: {},
      //selectPopupData: {},
      //initSearchFlag: false,
      //fileFullPathApp: '', // 전자서명 앱 중단건 파일경로
      //fileFullPathlocal: '', // 전자서명 앱 중단건 파일경로
      pChnlCustId: '',
      //searchKeyword: '',
      //radioValue: {},
      //radioTable: '1',
      tableLength: 0,
      //stopListPageNo: 1,
      //stopListTotCnt: 0,
      eltrnSignPageNo: 1,
      eltrnSignTotCnt: 0,
      //stopListCnt: 0, // 중단건 영수증 번호 조회 카운트
      //stopListVuchId: '', // 중단건 영수증번호
      //stopDeleteListVuchId: '', // 중단건 삭제 영수증번호 목록
      itemVuchId: '', // 영수증번호
      itemContNo: '', // 전자서명 완료건 선택한 Item 계약번호
      frtmPrmTrsfYmd: '', // 초회보험료이체신청일자
      scnAcpnCmpltYn: '', // 현장접수완료여부
      //stopListNoEltrnSrchFlag: false,
      //stopListNoLocalSrchFlag: false,

      pSElstIntrsCseReqSVO1: {},
      //pSElstIntrsCseReqSVO2: {},
      //pSElstPrgStatSVO: {},
      tableData1: [],
      //tableData2: [],
      orgTableData1: [], // 전체 리스트 목록 복사본
      sortFieldList1 : [],
      //orgTableData2: [], // 전체 리스트 목록 복사본
      holyDayChkDateArry: [], // 현재날짜+10일까지 날짜정보 배열
      //bef1SalesYmd: '', // 1전영업일
      //bef5SalesYmd: '', // 5전영업일
      //searchRes1: '',
      //searchRes2: '',
      //searchFlag: false, // 중단건 조회 여부
      //searchFlag2: false, // 완료건 조회 여부
      //selectItems1: {},
      //selectItems2: {},
      alert1: false,
      alert2: false,
      lv_CustInfoBottomSheet: null // 고객카드바텀시트
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    return
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
     tableData1(){
      this.tableLength   = this.tableData1 ? this.tableData1.length : 0
      this.isNodata  = this.tableLength == 0 ?  true : false
      console.log('watch tableData1() this.tableLength='+this.tableLength+ ' / this.isNodata='+this.isNodata)
    },
    slctTabIdx(){
      console.log(this.$options.screenId + " : this.slctTabIdx = "+ this.slctTabIdx)
    },
    srchOptData(){
      console.log(this.$options.screenId + " : this.srchOptData = ")
      console.log(this.srchOptData)
    }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler () {
      return
    },

  /*---------------------------------------------------------------------------------
  * UI Popup 관련 처리 영역
  ---------------------------------------------------------------------------------*/
    
    /******************************************************************************
     * Function명 : fn_PopupCustNmUiCard
     * 설명 : 계약자 피보험자 클릭시 nameUI 실행하는 함수
     ******************************************************************************/
    fn_PopupCustNmUiCard (pChnlCustId,pGubun) {
      //to-do 고객네임카드 연계 화면 처리
      if(pChnlCustId !== '-'){
        this.lv_CustInfoBottomSheet = this.$bottomModal.open(CustInfoBottomSheet, {
          properties: {
            chnlCustId: pChnlCustId,
            cnsltNo: PSServiceManager.getUserInfo('userId'), // ASR240200863 / 모바일청약 중단/완료건 화면에서 고객명 클릭시 고객정보 마스킹되는 현상 수정 
            //contNo : contNo
            parentId: 'MSPPS230M'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            }
          }
        })
      }
    }, 

    /******************************************************************************
     * Function명 : fn_PopupAcpnDfra
     * 설명 : 완료건 접수/출금 처리 화면 이동
     ******************************************************************************/
    fn_MovAcpnDfra (itemData){
      this.itemContNo = itemData.contNo
      this.itemVuchId = itemData.vuchId
      
      let trnstId = 'txTSSPS98S1'
      let data = {}
      let lv_Vm = this
      data.vuchId = itemData.vuchId
      this.post(lv_Vm, data, trnstId, 'S')
        .then(function (response) {
          lv_Vm.scnAcpnCmpltYn = response.body.scnAcpnCmpltYn
          lv_Vm.searchFlag2 = false
          lv_Vm.fn_ServiceCall('S1')
        })
    },

    /******************************************************************************
     * Function명 : fn_PopupAcpnDfra
     * 설명 : 완료건 접수불가 상태 팝업
     ******************************************************************************/
    fn_PopupAcpnDalw (showMesg){
      // showMsg가 공백인경우 : 200221, 모바일영업지원 오픈이전 접수불가건은 메시지를 저장하지 않음
      if (showMesg.trim() === '') {        
        showMesg = '지점에서 접수 가능합니다.'
      }

      this.getStore('confirm').dispatch('ADD', showMesg)
    },

    /******************************************************************************
     * Function명 : fn_PopupRtryTrt
     * 설명 : 완료건 전자서명 진행단계 재처리 상태 팝업
     ******************************************************************************/
    fn_PopupRtryTrt (item){
      this.retryPopup = this.$bottomModal.open(MSPPS233P, {
        properties: {
          rowPopupData : item         
        },
        listeners: {
          // 대상팝업 onPopupConfirm $emit 이벤트명 
          onPopupConfirm: (rtnData) => {
            this.$bottomModal.close(this.retryPopup)     // 모달 닫기
            //this.fn_ServiceData('S1')
            //this.fn_ServiceCall('S')
            this.fn_SearchEvent()
          }
        }
      })

    },

        /************************************************************************************************
     * Function명  : fn_RTransOpenPopup
     * 설명        : 팝업
     ************************************************************************************************/
    fn_RTransOpenPopup: function (slctItem) {
      this.slctItamData = slctItem
      let lv_Vm = this
      
      this.rpblPopup = this.$bottomModal.open(MSPPS234P, {
        properties: {
          pParntSrnId: this.$options.screenId, // 부모 화면 ID          
          pRowPopupData : this.slctItamData
        },
        listeners: { 
          // 대상팝업 onPopupCancel $emit 이벤트명 
          onPopupCancel : (rtnData) => {            
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기   
            
          },
          // 대상팝업 onPopupClose $emit 이벤트명 
          onPopupClose : (rtnData) => {            
             this.$bottomModal.close(this.rpblPopup) // 모달 닫기        
          }
        }
      })
    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명  : fn_SearchEvent
    * 설명        : 완료건 조회 케이스 처리
    *               고객명 / 상품명(필터) / 영수증번호(필터) 
                    검색 1 : 리스트출력 2 방식으로 동시호출로 편작
    ******************************************************************************/
  fn_SearchEvent () {
    if(! this.isInitSrvcCall) this.isInitSrvcCall = true
    
    let srchSc     = (this.srchOptData)? this.srchOptData.srchSc     : '1' //검색 구분 (1:고객명,2:상품명,3:영수증번호)
    let chnlCustId = (this.srchOptData)? this.srchOptData.chnlCustId : '' //고객명으로 검색시 선택한 채널 고객id
    let keyword    = (this.srchOptData)? this.srchOptData.keyword    : '' //검색 키워드 (고객명, 상품명 , 영수증번호)

    //서비스 호출 여부 판단
    if(! keyword) { // 검색어 공백으로 검색시 신규조회 
      this.tableData1 = this.orgTableData1 = []
      this.eltrnSignPageNo = 1

      this.fn_ServiceCall('S') //완료건 조회 

    } else if(this.orgTableData1) { //마지막 조회된 원본 데이터로 필터 처리

      this.tableData1 = []

      this.orgTableData1.forEach(item => {        
        switch (srchSc) {
          case PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key: //1:고객명             
            if ( chnlCustId &&  chnlCustId === item.mobslChnlCustId || chnlCustId === item.insrdChnlCustId ) {
                this.tableData1.push(item)
            }            
            break
          case PSConstants.PLAN_OFR_SEARCH_OPT_PRDT_NM.key: //2:상품명
            if (item.mnPrdtNm.indexOf(keyword) > -1) {
              this.tableData1.push(item)
            }
            break
          case PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM.key: //3:영수증번호
            if (item.vuchId.indexOf(keyword) > -1) {
              this.tableData1.push(item)
            }
            break        
        }//switch
      }) //forEach this.orgTableData1
      this.$emit('custnm-tableLength1', this.tableData1.length, this.sortFieldList1) // 검색건수 메인으로
      //정렬조건 초기화
      this.fn_SortReset()

      if(this.tableData1.length == 0) this.fn_TableStat('EPSC060') //검색결과가 존재하지 않습니다
    }  
        

  },
  /******************************************************************************
   * Function명  : fn_TableStat
   * 설명        : 데이터 없는 페이지 설정
   this.$t('ps')['EPSC012']
  *******************************************************************************/
  fn_TableStat (mesgTextCd,log =''){
    
    let mesgText = this.$t('ps')[mesgTextCd]
    console.log(">>>fn_TableStat mesgText : "+mesgText)
    this.mesgText = mesgText

    //this.$refs.nodataMesgBox.fn_SetMesgText(mesgText)
    //this.$refs.nodataMesgBox.mesgText = mesgText

  },

/******************************************************************************
    * Function명  : fn_ServiceCall
    * 설명        : 레거시&인터페이스 서비스 콜
    ******************************************************************************/
    fn_ServiceCall (serviceName) {      
      let pParams = {}
      
      switch (serviceName) {
        case 'S': // 현재날짜-10일까지 날짜정보
          window.vue.getStore('progress').dispatch('SKEL_LIST')
          pParams.appId = 'sli.ic'
          pParams.trnstId = ''
          pParams.fnctScCd = 'S'
          pParams.serviceId = ''
          pParams.reqSrvcNm = 'ICCommMknoCtlImpl'
          pParams.reqMthdNm = 'selListYmdInfo'
          pParams.reqVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
          pParams.resVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
          pParams.srnId = this.$options.screenId // 대표 화면 명

          //오늘날짜 기준으로 -10일 부터 조회 범위 설정
          if (! this.holyDayChkDateArry || this.holyDayChkDateArry.length === 0) {
            pParams.data = {slrcYmYmd: PSDateUtil.addDate(PSDateUtil.fn_CurrentDate(), -10)}
          } else {
            pParams.data = {slrcYmYmd: PSDateUtil.addDate(PSDateUtil.fn_CurrentDate(), -20)}
          }

          break
        case 'S1': // fn_MovAcpnDfra 호출 접수/출금 처리 화면 이동전 현장접수 현장출금 상태 조회
          
          pParams.appId = ''
          pParams.trnstId = ''
          pParams.fnctScCd = 'S'
          pParams.serviceId = 'C339_F1PQL0346_S'
          pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0346VO'
          pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0346VO'
          pParams.srnId = this.$options.screenId // 대표 화면 명
          pParams.data = {}
          pParams.data.zNcontTrtStatMobIfDto = {planId: this.itemVuchId}
          
          break
        default:
          break
      }

      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)

    },

    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceResultHandler (lv_Vm, moreData) {      
      switch (moreData) {
        case 'S': // 현재날짜-10일까지 날짜정보
          window.vue.getStore('progress').dispatch('PART')          
          if (lv_Vm.data !== null && lv_Vm.data.icymdMngVO.length > 0) {
            // console.log(lv_Vm.data.iCYmdMngVO)
            if ( this.holyDayChkDateArry.length === 0 ) {
              this.holyDayChkDateArry = lv_Vm.data.icymdMngVO
              this.fn_ServiceCall('S') // 장기 연휴 처리를 위한 날짜 10일 추가 조회
            } else {
              var arrLen = lv_Vm.data.icymdMngVO.length - 1
              for ( let i = arrLen; i > -1; i-- ) {
                this.holyDayChkDateArry.unshift(lv_Vm.data.icymdMngVO[i])
              }
              this.fn_ServiceData('S1')
            }   
                        
          }
          break
        case 'S1': // 진행현황
          if (lv_Vm.data && lv_Vm.data.zncontTrtStatMobIfDto !== null) {
            this.alert1 = false
            this.alert2 = false
            let o = {}
            for ( let i = 0; i < this.tableData1.length; i++ ) {
              if ( this.tableData1[i].vuchId === this.itemVuchId ) {
                o = this.tableData1[i]
                break
              }
            }
              
            if ( lv_Vm.data.zncontTrtStatMobIfDto.ofrStatCd.trim() === '91' ) {
              this.alert1 = true
              this.getStore('confirm').dispatch('ADD', '청약소멸건 입니다.')
            } else {
              // eslint-disable-next-line
              /*************************************************************************************************************************************************
              * 접수출금 버튼 터치시 상태확인
                  - 신계약진행현황의 ZaTrtYmd03(접수일), ZaTrtYmd04(출금일) 날짜 유무 및 현장접수/출금 이력으로 상태 판단
                    ①ZaTrtYmd03(접수일) N, ZaTrtYmd04(출금일) N, 현장접수 이력 N → 현장접수 화면으로 이동
                    ②ZaTrtYmd03(접수일) N, ZaTrtYmd04(출금일) N, 현장접수 이력 Y → (접수취소건) 현장접수 화면으로 이동
                    ③ZaTrtYmd03(접수일) Y, ZaTrtYmd04(출금일) N, 현장접수 이력 N → "지점출금 안내"메세지 노출하고 신모바일서버 데이터 업데이트, 목록 재조회시 '-'표시
                    ④ZaTrtYmd03(접수일) Y, ZaTrtYmd04(출금일) N, 현장접수 이력 Y → 현장출금 화면으로 이동
                    ④ZaTrtYmd03(접수일) Y, ZaTrtYmd04(출금일) Y, 현장접수 이력 Y/N → '출금완료'메세지 노출하고 신모바일서버 데이터 업데이트, 목록 재조회시 '-'표시
              *************************************************************************************************************************************************/
              let tempYmd03 = '' // 접수일 여부 판단
              let tempYmd04 = '' // 출금일 여부 판단

              if ( lv_Vm.data.zncontTrtStatMobIfDto.trtYmd03 !== null && lv_Vm.data.zncontTrtStatMobIfDto.trtYmd03.trim().length > 0 ) {
                tempYmd03 = 'Y'
              } else tempYmd03 = 'N'
              if ( lv_Vm.data.zncontTrtStatMobIfDto.trtYmd04 !== null && lv_Vm.data.zncontTrtStatMobIfDto.trtYmd04.trim().length > 0 ) {
                tempYmd04 = 'Y'
              } else tempYmd04 = 'N'

              let scnAcpnCmpltYn = this.scnAcpnCmpltYn.trim() // 현장접수완료여부

              if ( tempYmd03 === 'N' && tempYmd04 === 'N' && scnAcpnCmpltYn === 'N' ) {
                this.$MenuManager.fn_RouterPushSrnId('MSPPS610M', {reqNameID: 'MSPPS240M_1', itemData: o, step: '1', mobpsYn: 'Y'}) // 현장접수 화면으로 이동, 모바일청약여부 YN
              } else if ( tempYmd03 === 'N' && tempYmd04 === 'N' && scnAcpnCmpltYn === 'Y' ) {
                // 4월 15일 발행복원 기능 추가로 지점 취소와 구분이 불가능 하여 모두 지점 접수로 안내메세지 처리 하도록 수정
                // 지점 출금 안내 상태와 동일하여 구분이 되지 않게 됨.
                this.alert1 = true
                this.getStore('confirm').dispatch('ADD', '초회보험료 미출금으로 접수취소되어\n지점에서 재접수 가능합니다.')
                
                // this.$MenuManager.fn_RouterPushSrnId('MSPPS610M', {reqNameID: 'TSSPS213D_1', itemData: o, step: '1'}) // 현장접수 화면으로 이동
              } else if ( tempYmd03 === 'Y' && tempYmd04 === 'N' && scnAcpnCmpltYn === 'N' ) {
                this.alert1 = true
                this.getStore('confirm').dispatch('ADD', '지점 입금처리 대상 건입니다.\n지점으로 연락 바랍니다.')
                
              } else if ( tempYmd03 === 'Y' && tempYmd04 === 'N' && scnAcpnCmpltYn === 'Y' ) {
                if (o.frtmPrmPadMthCd === '1' || o.frtmPrmPadMthCd === '9') {
                  this.$MenuManager.fn_RouterPushSrnId('MSPPS610M', {reqNameID: 'MSPPS240M_2', itemData: o, step: '2', mobpsYn: 'Y'}) // 현장출금 화면으로 이동
                } else if (o.frtmPrmPadMthCd === '4') {
                  // 가상계좌의 경우 알림창 후 출금 불가 처리 오픈 이후 반영 예정
                  this.alert1 = true
                  this.getStore('confirm').dispatch('ADD', '가상계좌 접수건은 23시까지 보험료 입금을 완료하여 주시기 바랍니다.') // 4/6 최주연선임 변경 요청 가상계좌 접수건은 지점에서 출금 가능합니다.'
                  // 23.12 ASR230901006 [모바일청약] 신계약 가상계좌 및 카카오페이 납입 시간 확대에 따른 프로그램 변경 20시 -> 23시
                  
                } else if (o.frtmPrmPadMthCd === 'F') { // ASR221100288_간편납입(카카오PAY)(23.02.21_PJO)
                  this.alert1 = true
                  this.getStore('confirm').dispatch('ADD', '카카오페이 납입 접수건은 23시까지 신청을 완료하여 주시기 바랍니다.') // 23.12 ASR230901006 [모바일청약] 신계약 가상계좌 및 카카오페이 납입 시간 확대에 따른 프로그램 변경 20시 -> 23시
                }
              } else if ( tempYmd03 === 'Y' && tempYmd04 === 'Y' ) {
                  this.alert2 = true
                  this.getStore('confirm').dispatch('ADD', '초회보험료 이체완료건입니다.')
              } else {
              }

              if ( tempYmd03 === 'Y' && scnAcpnCmpltYn === 'N' ) { // 지점접수 업데이트
                this.fn_ServiceData('S6')
              }
            }

            if(this.alert1){
              this.frtmPrmTrsfYmd = '99999999'
              this.fn_ServiceData('S5')
            }
            if(this.alert2){
              this.fn_ServiceData('S3')
            }

          }
          break
        default:
          break
      }
    },
    fn_ServiceFaultHandler (event) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      pParams.srnId = this.$options.screenId // 대표 화면 명
      let lv_Vm = this
      let trnstId = ''

      switch (serviceName) {
        case 'S1': // 모바일청약 완료목록조회
          lv_Vm.pSElstIntrsCseReqSVO1 = {            
            // 2020.03.05 최주연 프로  완료건 조회기간 변경 15일 -> 2년            
            // 2023.07.20 2년 -> 100일 변경
            minusDay: '100', 
            pageNo: lv_Vm.eltrnSignPageNo,
            pageSize: 10
          }
          pParams = lv_Vm.pSElstIntrsCseReqSVO1
          trnstId = 'txTSSPS50S1'
          window.vue.getStore('progress').dispatch('SKEL_LIST')
          break
        case 'S1_N':
          if ( lv_Vm.tableData1.length >= lv_Vm.eltrnSignTotCnt || lv_Vm.eltrnSignTotCnt <= 10 ) {
            return
          }
          lv_Vm.pSElstIntrsCseReqSVO1.pageNo = ++lv_Vm.eltrnSignPageNo
          pParams = lv_Vm.pSElstIntrsCseReqSVO1
          trnstId = 'txTSSPS50S1'
          break
        case 'S3': // 초회보험료 이체여부 완료 업데이트
          pParams = {
            elstPrgStatCd: '40',
            fofDfraYn: 'Y',
            frtmPrmCmpltYn: 'Y',
            vuchId: lv_Vm.itemVuchId
          }
          trnstId = 'txTSSPS98U11'
          break
        case 'S5': // 초회보험료이체신청일자 업데이트
          pParams = {
            frtmPrmTrsfYmd: this.frtmPrmTrsfYmd,
            vuchId: this.itemVuchId
          }
          trnstId = 'txTSSPS98U8'
          break
        case 'S6': // 지점접수여부 업데이트
          pParams = {
            vuchId: this.itemVuchId,
            fofAcpnYn: 'Y'
          }
          trnstId = 'txTSSPS98U14'
          break
        default:
          break
      } // end switch

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          switch (serviceName) {
            case 'S1':
              window.vue.getStore('progress').dispatch('PART')
            case 'S1_N':
              let t_rslt = response.body.pSElstIntrsCseListDVO
              let t_rowIndex = lv_Vm.tableData1.length
              if (t_rslt && t_rslt.length > 0) {
                
                let sortArray = []
                t_rslt.forEach(pIteam => {
                  pIteam.id = t_rowIndex++
                  pIteam.step = 0
                  // 설계명에 삼성생명, 삼성 제거 처리
                  pIteam.mnPrdtNm = pIteam.mnPrdtNm.replace('삼성생명', '')
                  pIteam.mnPrdtNm = pIteam.mnPrdtNm.replace('삼성', '')
                  pIteam.mnPrdtNm = pIteam.mnPrdtNm.trim()
                  pIteam.ofrMmgNm = '-'
                  pIteam.prgPhseNm = '-'
                  // pIteam.joinDate = pIteam.elstPblYmd
                  // pIteam.elstPblYmd = pIteam.elstPblYmd.trim().length > 0 ? DateUtil.fn_DateFormat(pIteam.elstPblYmd, 'MM-dd') : '-'

                  // 191115 현장접수 청약일 조건변경
                  // 계약일 익영업일까지 접수 가능함
                  // 1) 초회보험료납입코드 1:실시간이체, 9:카드납입 -> 계약일=출금동의일자(DFRA_CNSNT_YMD)
                  // 2) 초회보험료납입코드 4:가상계좌입금 -> 계약일=전자서명발행일자(청약일자)(ELST_PBL_YMD)
                  console.log(pIteam.frtmPrmPadMthCd  + '::::' + pIteam.dfraCnsntYmd + '::::' + pIteam.elstPblYmd)
                  if (pIteam.frtmPrmPadMthCd === '1' || pIteam.frtmPrmPadMthCd === '9') {
                    pIteam.joinDate = pIteam.dfraCnsntYmd // 1) 초회보험료납입코드 1:실시간이체, 9:카드납입 -> 계약일=출금동의일자(DFRA_CNSNT_YMD)
                  } else {
                    pIteam.joinDate = pIteam.elstPblYmd // 2) 초회보험료납입코드 4:가상계좌입금 F:간편납입 -> 계약일=전자서명완료일자(ELST_CNCLN_YMD)
                  }
                  // 191231 ASR191200905 청약일자 공백時 전자서명 완료건 목록 미 표시
                  pIteam.elstPblYmd = (pIteam.joinDate && pIteam.joinDate.trim().length > 0) ? PSDateUtil.fn_DateFormat(pIteam.joinDate, 'MM-dd') : '-'
                  pIteam.elstPblYmd2 = (pIteam.joinDate && pIteam.joinDate.trim().length > 0) ? PSDateUtil.fn_DateFormat(pIteam.joinDate, 'yyyy-MM-dd') : '-'

                  // 01 : email, 02 : 서면 ,03 : 모바일+Email , 04 : 모바일
                  if (pIteam.isuMthScCd === '01') {
                    pIteam.fstIsuMthScNm = '이메일'
                  } else if (pIteam.isuMthScCd === '02') {
                    pIteam.fstIsuMthScNm = '지점인쇄'
                  } else if (pIteam.isuMthScCd === '03') {
                    pIteam.fstIsuMthScNm = '알림톡&이메일'
                  } else if (pIteam.isuMthScCd === '04') {
                    pIteam.fstIsuMthScNm = '알림톡'
                  } else {
                    pIteam.fstIsuMthScNm = '미선택'
                  } // end else if

                  // 현장 접수 완료가 'Y'이고 초회보험료이체여부과 'N'일 때
                  // 초회보험료이체일자 + 1 보다 같거나 작을 때 까지 현장출금 버튼 활성화
                  if (pIteam.elstPrgStatCd === '40') { // 전자서명진행상태코드(elstPrgStatCd) : 40:이체완료
                    // pIteam.prgPhseNm = '-' // 이체완료
                    pIteam.prgPhseNm = '출금완료' // 이체완료
                  } else {
                    if (pIteam.scnAcpnCmpltYn === 'Y') { // 현장접수완료여부(scnAcpnCmpltYn)
                      pIteam.prgPhseNm = '-' // 접수완료
                      // 현장출금 여부
                      if (pIteam.frtmPrmCmpltYn !== 'Y') { // 초회보험료이체여부(frtmPrmCmpltYn)
                        if (pIteam.scnAcpnCmpltYmd === PSDateUtil.fn_CurrentDate()) { // 현장접수완료일자(scnAcpnCmpltYmd), 현장출금버튼은 현장접수완료일 당일만 표시
                          if (pIteam.frtmPrmPadMthCd === '1' || pIteam.frtmPrmPadMthCd === '9') { // 초회보험료납입방법코드(frtmPrmPadMthCd) 1:실시간이체, 9:카드납입
                            if (pIteam.frtmPrmTrsfYmd === '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd), 접수 결과 미 수신건 재접수 시 현장출금 예외처리
                              pIteam.ofrMmgNm = '-'
                            } else {
                              pIteam.ofrMmgNm = '접수/출금'
                            }
                          } else {
                            pIteam.ofrMmgNm = '-'
                          }
                        } else {
                          pIteam.ofrMmgNm = '-'
                        } // end else if
                      } // end if
                      // 현장접수/지점취소의 경우 버튼 표시
                      if (lv_Vm.fn_currntDayPlusDateChk(1) <= pIteam.joinDate) { // 워크데이 기준 D+1일 현장접수 버튼 표시
                        if ( pIteam.frtmPrmTrsfYmd !== '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd)
                          pIteam.ofrMmgNm = '접수/출금'
                        } else pIteam.ofrMmgNm = '-'
                      }
                      if (pIteam.scnAcpnPsbYn === 'N') { // 접수불가건
                        pIteam.ofrMmgNm = '접수불가'
                      }
                    } else { // 현장접수완료여부(scnAcpnCmpltYn) : N
                      if (pIteam.elst5PhseCmpltYn === 'Y') { // 전자서명5단계완료여부(elst5PhseCmpltYn)
                        pIteam.prgPhseNm = '-' // 전자서명완료
                        pIteam.ofrMmgNm = '-'
                        // 워크데이 기준 D+1일 현장접수 버튼 표시
                        // 191213 : 오늘년도와 계약일자의 년도의 동일한지 검증 추가
                        //        : 회계년도 마감으로 20191231에 전자서명완료건은 20200101,02에 접수할수 없음
                        if (lv_Vm.fn_currntDayPlusDateChk(1) <= pIteam.joinDate) { // && PSDateUtil.fn_CurrentDate().substr(0, 4) === pIteam.joinDate.substr(0, 4)) { //회계년도 마감 로직 삭제
                          if ( pIteam.frtmPrmTrsfYmd !== '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd)
                            pIteam.ofrMmgNm = '접수/출금'
                          } else pIteam.ofrMmgNm = '-'
                        }
                        if (pIteam.scnAcpnPsbYn === 'N') { // 접수불가건
                          pIteam.ofrMmgNm = '접수불가'
                        }
                        if (pIteam.scnAcpnPsbYn !== 'Y' && pIteam.scnAcpnPsbYn !== 'N') { // 5단계가 완료되어도, 현장접수여부가 확정되지 않은 경우 재처리 필요
                          pIteam.ofrMmgNm = '-'
                          pIteam.prgPhseNm = '재처리'
                        }
                      } else if (pIteam.elst2PhseCmpltYn === 'Y') { // 전자서명2단계완료여부(elst2PhseCmpltYn)
                        pIteam.prgPhseNm = '재처리'
                        pIteam.ofrMmgNm = '-'
                        // 워크데이 기준 D+1일 현장접수 버튼 표시
                        // 191213 : 오늘년도와 계약일자의 년도의 동일한지 검증 추가
                        //        : 회계년도 마감으로 20191231에 전자서명완료건은 20200101,02에 접수할수 없음
                        if (lv_Vm.fn_currntDayPlusDateChk(1) <= pIteam.joinDate) { //  && PSDateUtil.fn_CurrentDate().substr(0, 4) === pIteam.joinDate.substr(0, 4)) { //회계년도 마감 로직 삭제
                          if ( pIteam.frtmPrmTrsfYmd !== '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd)
                            pIteam.ofrMmgNm = '접수/출금'
                          } else pIteam.ofrMmgNm = '-'
                        }
                        if (pIteam.scnAcpnPsbYn === 'N') { // 접수불가건
                          pIteam.ofrMmgNm = '접수불가'
                        }
                      } else {
                        pIteam.ofrMmgNm = '-'
                        pIteam.prgPhseNm = '재처리'
                      }
                    } // end else if
                  }
                  // 1월 7일 이전 발행건의 [접수/출금] 버튼 숨김 처리
                  if (pIteam.joinDate < '20190107') {
                    pIteam.ofrMmgNm = '-'
                    pIteam.prgPhseNm = '-'
                  }

                  // 1월 7일 이전 발행건의 [접수/출금] 버튼 숨김 처리
                  sortArray.push(pIteam)
                })
                if ( serviceName === 'S1' ) {
                  lv_Vm.orgTableData1 = lv_Vm.tableData1 = sortArray
                } else {
                  sortArray.forEach(item => {
                    // lv_Vm.orgTableData1.push(item)
                    lv_Vm.tableData1.push(item)
                    // console.log(item.mnContrNm + ',' + item.insrdNm + ',' + item.vuchId)
                  })
                }
                lv_Vm.tableLength = lv_Vm.tableData1.length
                lv_Vm.eltrnSignTotCnt = response.body.totCnt
                lv_Vm.fn_SetOrderByFields(lv_Vm.tableLength) // 정렬 컴포넌트 필드정의 건수 셋팅
                // if (serviceName === 'S1') lv_Vm.fn_SetOrderByFields(lv_Vm.tableLength) // 정렬 컴포넌트 필드정의 건수 셋팅
                // else lv_Vm.$emit('custnm-tableLength1', lv_Vm.tableLength, lv_Vm.sortFieldList1) // 더보기시 검색건수만 메인으로
                //ody 펼침추가
                lv_Vm.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
                  for(let i = 0 ; i < lv_Vm.tableData1.length; i++){
                    lv_Vm.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
                  }
                });
                //ody 펼침추가

              } else {
                if (serviceName === 'S1') lv_Vm.fn_TableStat('EPSC060') // 조회결과 없음 처리
              }
              break
            case 'S3':
              if ( response.body !== null ) {
                for ( let i = 0; i < lv_Vm.tableData1.length; i++ ) {
                  if ( lv_Vm.tableData1[i].vuchId === lv_Vm.itemVuchId ) {
                    lv_Vm.tableData1[i].ofrMmgNm = '-'
                  }
                }
              }
              break
            case 'S5':
              if ( response.body !== null ) {
                for ( let i = 0; i < lv_Vm.tableData1.length; i++ ) {
                  if ( lv_Vm.tableData1[i].vuchId === lv_Vm.itemVuchId ) {
                    lv_Vm.tableData1[i].ofrMmgNm = '-'
                  }
                }
              }
              break
            case 'S6':
              if ( response.body !== null ) {
              }
              break
          }
          lv_Vm.isLoadingStatus = false
        })
        .catch(function (error) {
          lv_Vm.fn_TableStat('EPSC060')
          window.vue.error(error)
        })
    },

/*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/
  
  /******************************************************************************
  * Function명  : fn_SetOrderByFields
  * 설명        : 정렬 컴포넌트 필드정의 셋팅
  *               화면에서 소트 컨트롤 방식 셋팅 fn_SortChangeHandler 에서 처리
  * 
  ******************************************************************************/
  fn_SetOrderByFields (tableLength) {
      this.sortFieldList1 = [
         {field : 'elstPblYmd2', label : '청약일'      , sort: 'DESC'}
        ,{field : 'mnContrNm' , label : '계약자'      , sort: 'ASC'}
        ,{field : 'insrdNm'   , label : '피보험자'    , sort: 'ASC'}
        ,{field : 'aisdNm'    , label : '종피보험자'  , sort: 'ASC'}
      ]
      this.$emit('custnm-tableLength1', tableLength, this.sortFieldList1) // 검색건수 메인으로
  },
  /******************************************************************************
  * Function명  : fn_SortChangeHandler
  * 설명        : 정렬 이벤트 핸들러
  *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
  ******************************************************************************/
  fn_SortChangeHandler (pSortOrder){
    console.log('fn_SortChangeHandler !!!')

    let sortField = pSortOrder.field
    let sortOrderby = pSortOrder.sort //ASC or DESC

    console.log(pSortOrder)
    console.log('sortField = '+sortField+ ' , sortOrderby = '+sortOrderby)
    
    switch(sortField){
      case 'mnContrNm':  //String 형 sort
      case 'insrdNm':  //String 형 sort
      case 'aisdNm':  //String 형 sort
        PSCommUtil.fn_SortListValueString(sortOrderby, this.tableData1, sortField)
        break
      case 'elstPblYmd2': // 날짜형 String 을 Date 형으로 변환 처리 해서 sort
        PSCommUtil.fn_SortListValueDateString(sortOrderby, this.tableData1, sortField, 'yyyy-MM-dd') //yyyy-MM-dd
        break
      //case 'codeVal': //수치형 String 을 Number로 변환 처리  해서 sort
      //case 'priceVal': //수치형 sort
      //  PSCommUtil.fn_SortListValueNumber(sortOrderby, this.tableData1, sortField)
      //  break;    
    }//switch

  },

  /******************************************************************************
  * Function명  : fn_SortReset
  * 설명        : 정렬 리셋 이벤트 핸들러
  ******************************************************************************/
  fn_SortReset(){
    console.log('fn_SortReset !!!')
    // 원본 되돌리기
    // this.tableData1 = []      
    // this.orgTableData1.forEach(item => {
    //   this.tableData1.push(Object.assign({},item))
    // });

    // this.$refs.sortFieldComp.fn_Reset()
    this.$emit('sortReset')
  },

  /******************************************************************************
   * Function명  : fn_CurrntDayPlusDateChk
   * 설명        : 현재날짜-10일까지 날짜정보 처리 -> 
                   공휴일을 제외한 워킹데이 처리할때 사용하면 됨
  *******************************************************************************/
  fn_currntDayPlusDateChk(cnt) {
    let dayCnt = 0
    let holyDayChkDate = ''
    let i = this.holyDayChkDateArry.length - 1
    for ( i; i > 0; i-- ) {
      if ( this.holyDayChkDateArry[i].holyCd === '00') {
        dayCnt++
      }
      if ( dayCnt === cnt ) {
        holyDayChkDate = this.holyDayChkDateArry[i].slrcYmYmd
        break
      }
    }
    return holyDayChkDate
  },


  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>