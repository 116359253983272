/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS232D
 * 화면 설명: 청약 파트 설계청약현황
 *            모바일청약 중단건목록
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container v-if="!isNodata" alignV="start" componentid="" direction="column" class="status-list type-expand">
      <mo-list :list-data="tableData2">
        <template #list-item="{item, index}">      
          <mo-list-item class=""> 
            <msp-expand btn-area-first title-first expandable :preventScrollWhenExpand="true" :ref="'expItem_'+index" class="mo-list-expand w100" btn-area-class="fexTy2 firstdiv-full fexJsCtTy1"> 
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title pt6">
                    <span class="name txtSkip fs19rem fwb">{{item.mnPrdtNm}}</span>
                  </div>
                  <div class="list-item__contents__info mt6">
                    <span><span class="fs14rem mr6 crTy-bk7">피보험자</span><span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1 underline" @click.stop="fn_PopupCustNmUiCard(item.insrdChnlCustId,'주피보험자')">{{item.insrdNm === ' ' ? item.mnContrNm : item.insrdNm}}</span></span><span>
                    <em class="em_normal">|</em>
                    <span class="fs14rem mr8 crTy-bk7">청약일</span><span class="crTy-bk1 fs16rem ls--1">{{item.insrOfrYmd}}</span></span>
                  </div>
                  <div class="list-item__contents__info mt6 mb10">
                    <span><span class="fs14rem mr8 crTy-bk7">보험료</span><span class="crTy-bk1 fs16rem fwb crTy-blue3">{{$bizUtil.numberWithCommasCurr(item.contPrm, item.crncyCd, 'Y', 'Y', 3)}}</span></span>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow down mt10"></mo-button>
              </template>
              <template #content>
                <div class="list-item-detail gray_style bdnone margin--full pt20 pb20">
                  <ur-box-container class="con--padding ">
                    <div class="contents-row">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">계약자</span><span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1 underline" @click.stop="fn_PopupCustNmUiCard(item.mobslChnlCustId,'계약자')">{{item.mnContrNm}}</span>
                    </div>
                    <div class="contents-row mt4">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">영수증번호</span><span class="fs16rem">{{item.vuchId}}</span>
                    </div>
                    <div class="contents-row mt4">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">종피보험자</span><span class='crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1' :class="[{'' : item.aisdNm === '-'}, {'underline' : item.aisdNm !== '-'}]" @click.stop="fn_PopupCustNmUiCard(item.aisdChnlCustId,'종피보험자')">{{item.aisdNm}}</span>
                    </div>
                  </ur-box-container>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
            <div class="ns-btn-relative-area mt0">
              <div class="ns-btn-relative-s">
                <div class="relative-div mb5">
                  <mo-button componentid="" class="ns-btn-round white ml0 mr0" @click="fn_PrgPhseOpenPopup(item)">{{item.prcssStatus}}<span class="crTy-red1" v-if="item.insrdCnsntObjYn === 'Y' && item.insrdCnsntScCd === 'U'">&nbsp;(피보험자 동의 필요)</span></mo-button><!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
                </div>
              </div>
            </div>
          </ur-box-container>
        </template>
      </mo-list>
    </ur-box-container>

    <!-- 중단건 NoData 영역 ur-box-containe  -->        
    <PSNodataMesgBoxForList v-if="isNodata" ref="nodataMesgBox" :mesgText="mesgText"/>        
    <!-- / 중단건 NoData 영역 ur-box-containe -->  
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import MSPPS231P from '@/ui/ps/MSPPS231P'
import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'



export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS232D', 
  screenId: 'MSPPS232D',

  components: {
    'PSNodataMesgBoxForList' : PSNodataMesgBoxForList, // mo-list 전용 데이터 없음 박스
    'MSPPS231P' : MSPPS231P // 모바일청약 진행현황 팝업
    
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    slctTabIdx : {type:Number, default:1}, // 부모 선택한 현재 탭 인덱스
    srchOptData : {type:Object, default: null}, // 부모 검색 상태
    pvuchIdData : {type:String, default: ''},  // 모바일청약 정보확인후 띄울 팝업 vuchId
    vGssAuthYN : {type:String, default: ''}
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      isInitSrvcCall: false,       // 최초 서비스 호출 한적 있는지 여부
      PSConstants   : PSConstants, // 청약 상수
      mesgText      : '',          // 데이터 없음 표시 메세지
      radioValue    : {} ,        // 선택된 라디오 값
      //slctItamData : null,        // 선택된 item Row Object Data  
     //rsltCnt       : 0,          // 출력리스트 갯수
      isNodata      : false,      // 데이터 없음 Flag      
      //rowPopupData: {},
      //selectPopupData: {},
      //initSearchFlag: false,
      //fileFullPathApp: '', // 전자서명 앱 중단건 파일경로
      //fileFullPathlocal: '', // 전자서명 앱 중단건 파일경로
      pChnlCustId: '',
      //searchKeyword: '',
      //radioValue: {},
      //radioTable: '1',
      tableLength: 0,
      stopListPageNo: 1,
      stopListTotCnt: 0,
      //eltrnSignPageNo: 1,
      //eltrnSignTotCnt: 0,
      //stopListCnt: 0, // 중단건 영수증 번호 조회 카운트
      stopListVuchId: '', // 중단건 영수증번호
      //stopDeleteListVuchId: '', // 중단건 삭제 영수증번호 목록
      //itemVuchId: '', // 영수증번호
      //itemContNo: '', // 전자서명 중단건 선택한 Item 계약번호
      //frtmPrmTrsfYmd: '', // 초회보험료이체신청일자
      //scnAcpnCmpltYn: '', // 현장접수완료여부
      //stopListNoEltrnSrchFlag: false,
      //stopListNoLocalSrchFlag: false,

      //pSElstIntrsCseReqSVO1: {},
      pSElstIntrsCseReqSVO2: {},
      //pSElstPrgStatSVO: {},
      //tableData1: [],
      tableData2: [],
      //orgTableData1: [], // 전체 리스트 목록 복사본
      orgTableData2: [], // 전체 리스트 목록 복사본
      sortFieldList2 : [],
      holyDayChkDateArry: [], // 현재날짜+10일까지 날짜정보 배열
      //bef1SalesYmd: '', // 1전영업일
      //bef5SalesYmd: '', // 5전영업일
      //searchRes1: '',
      //searchRes2: '',
      //searchFlag: false, // 중단건 조회 여부
      //searchFlag2: false, // 중단건 조회 여부
      //selectItems1: {},
      //selectItems2: {},
      lv_CustInfoBottomSheet: null // 고객카드바텀시트
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    return
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
     tableData2(){
      this.tableLength   = this.tableData2 ? this.tableData2.length : 0
      this.isNodata  = this.tableLength == 0 ?  true : false
      console.log('watch tableData2() this.tableLength='+this.tableLength+ ' / this.isNodata='+this.isNodata)
    },
    slctTabIdx(){
      console.log(this.$options.screenId + " : this.slctTabIdx = "+ this.slctTabIdx)
    },
    srchOptData(){
      console.log(this.$options.screenId + " : this.srchOptData = ")
      console.log(this.srchOptData)
    }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler () {
      return
    },

  /*---------------------------------------------------------------------------------
  * UI Popup 관련 처리 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
     * Function명 : fn_PopupCustNmUiCard
     * 설명 : 계약자 피보험자 클릭시 nameUI 실행하는 함수
     ******************************************************************************/
    fn_PopupCustNmUiCard (pChnlCustId,pGubun) {
      if(this.vGssAuthYN === 'Y') return

      //to-do 고객네임카드 연계 화면 처리
      if(pChnlCustId !== '-'){
        this.lv_CustInfoBottomSheet = this.$bottomModal.open(CustInfoBottomSheet, {
          properties: {
            chnlCustId: pChnlCustId,
            cnsltNo: PSServiceManager.getUserInfo('userId'), // ASR240200863 / 모바일청약 중단/완료건 화면에서 고객명 클릭시 고객정보 마스킹되는 현상 수정 
            //contNo : contNo
            parentId: 'MSPPS230M'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            }
          }
        })
      }
    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/

  /******************************************************************************
    * Function명  : fn_serviceCall
    * 설명        : 레거시&인터페이스 서비스 콜
    ******************************************************************************/
    fn_ServiceCall (div) {
      let pParams = {}
      let moreData = ''
      switch (div) {
        case 'S': // 현재날짜-10일까지 날짜정보
          window.vue.getStore('progress').dispatch('SKEL_LIST')
          moreData = 'S'
          pParams.appId = 'sli.ic'
          pParams.trnstId = ''
          pParams.fnctScCd = 'S'
          pParams.serviceId = ''
          pParams.reqSrvcNm = 'ICCommMknoCtlImpl'
          pParams.reqMthdNm = 'selListYmdInfo'
          pParams.reqVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
          pParams.resVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
          pParams.srnId = this.$options.screenId // 대표 화면 명
          if (this.holyDayChkDateArry.length === 0) {
            pParams.data = {slrcYmYmd: PSDateUtil.addDate(PSDateUtil.fn_CurrentDate(), -10)}
          } else {
            pParams.data = {slrcYmYmd: PSDateUtil.addDate(PSDateUtil.fn_CurrentDate(), -20)}
          }
          // pParams.data = {slrcYmYmd: '20190328'}
          break
        default:
          break
      }
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, moreData, this.fn_ServiceFaultHandler)
    },
    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceResultHandler (lv_Vm, moreData) {
      switch (moreData) {
        case 'S': // 현재날짜-10일까지 날짜정보
          window.vue.getStore('progress').dispatch('PART')
          if (lv_Vm.data !== null && lv_Vm.data.icymdMngVO.length > 0) {
            // console.log(lv_Vm.data.iCYmdMngVO)
            if ( this.holyDayChkDateArry.length === 0 ) {
              this.holyDayChkDateArry = lv_Vm.data.icymdMngVO
              this.fn_ServiceCall('S') // 장기 연휴 처리를 위한 날짜 10일 추가 조회
            } else {
              var arrLen = lv_Vm.data.icymdMngVO.length - 1
              for ( let i = arrLen; i > -1; i-- ) {
                this.holyDayChkDateArry.unshift(lv_Vm.data.icymdMngVO[i])
              }
              this.fn_ServiceData('S2')
            }
          }
          break
        default:
          break
      }
    },
    fn_ServiceFaultHandler (event) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

  /******************************************************************************
    * Function명  : fn_SearchEvent
    * 설명        : 중단건 조회 케이스 처리
    *               고객명 / 상품명(필터) / 영수증번호(필터) 
                    검색 1 : 리스트출력 2 방식으로 동시호출로 편작
    ******************************************************************************/
  fn_SearchEvent () {
    if(! this.isInitSrvcCall) this.isInitSrvcCall = true
    
    let srchSc     = (this.srchOptData)? this.srchOptData.srchSc     : '1' //검색 구분 (1:고객명,2:상품명,3:영수증번호)
    let chnlCustId = (this.srchOptData)? this.srchOptData.chnlCustId : '' //고객명으로 검색시 선택한 채널 고객id
    let keyword    = (this.srchOptData)? this.srchOptData.keyword    : '' //검색 키워드 (고객명, 상품명 , 영수증번호)

    //서비스 호출 여부 판단
    if(! keyword) { // 검색어 공백으로 검색시 신규조회 
      this.tableData2 = this.orgTableData2 = []
      this.stopListPageNo = 1
      
      //this.fn_ServiceData('S2') //중단건 조회 
      this.fn_ServiceCall('S')
     
    } else if(this.orgTableData2) { //마지막 조회된 원본 데이터로 필터 처리

      this.tableData2 = []

      this.orgTableData2.forEach(item => {        
        switch (srchSc) {
          case PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key: //1:고객명             
            if ( chnlCustId &&  chnlCustId === item.mobslChnlCustId || chnlCustId === item.insrdChnlCustId ) {
                this.tableData2.push(item)
            }            
            break
          case PSConstants.PLAN_OFR_SEARCH_OPT_PRDT_NM.key: //2:상품명
            if (item.mnPrdtNm.indexOf(keyword) > -1) {
              this.tableData2.push(item)
            }
            break
          case PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM.key: //3:영수증번호
            if (item.vuchId.indexOf(keyword) > -1) {
              this.tableData2.push(item)
            }
            break        
        }//switch
      }) //forEach this.orgTableData2
      this.$emit('custnm-tableLength2', this.tableData2.length, this.sortFieldList2) // 검색건수 메인으로
      //정렬조건 초기화
      this.fn_SortReset()

      if(this.tableData2.length == 0) this.fn_TableStat('EPSC060') //검색결과가 존재하지 않습니다
    }  
        
  },

  /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      pParams.srnId = this.$options.screenId // 대표 화면 명
      let lv_Vm = this
      let trnstId = ''

      switch (serviceName) {
        case 'S2': // 중단건 조회
          lv_Vm.pSElstIntrsCseReqSVO2 = {
            predVuchId: lv_Vm.stopListVuchId,
            pageNo: lv_Vm.stopListPageNo,
            pageSize: 10,
            bef1SalesYmd: lv_Vm.fn_currntDayPlusDateChk(1),
            bef5SalesYmd: lv_Vm.fn_currntDayPlusDateChk(6)
            //bef5SalesYmd: '20200801'
          }
          // GA 매니저 대행 컨설턴트 변경시
          console.log(lv_Vm.$cmUtil.fn_GachangeTF())
          if(lv_Vm.$cmUtil.fn_GachangeTF()){
            lv_Vm.pSElstIntrsCseReqSVO2.userEno = lv_Vm.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
          }
          pParams = lv_Vm.pSElstIntrsCseReqSVO2
          //trnstId = 'txTSSPS50S2'
          trnstId = 'txTSSPS50S6' // 사랑온NEXT 조회컬럼추가용
          window.vue.getStore('progress').dispatch('SKEL_LIST')
          break
        case 'S2_N':
          if ( lv_Vm.tableData2.length >= lv_Vm.stopListTotCnt || lv_Vm.stopListTotCnt <= 10 ) {
            return
          }
          lv_Vm.pSElstIntrsCseReqSVO2.pageNo = ++lv_Vm.stopListPageNo
          pParams = lv_Vm.pSElstIntrsCseReqSVO2
          //trnstId = 'txTSSPS50S2'
          trnstId = 'txTSSPS50S6' // 사랑온NEXT 조회컬럼추가용
          break
        
        default:
          break
      } // end switch

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          switch (serviceName) {
            case 'S2':
              window.vue.getStore('progress').dispatch('PART')
            case 'S2_N':
              if ( response.body !== null ) {
                lv_Vm.searchFlag = true
                let t_rslt2 = response.body.pSElstIntrsCseListDVO

                if (t_rslt2 && t_rslt2.length > 0) {
                  let sortArray2 = []

                  t_rslt2.forEach(pIteam => {
                    // 청약일
                    pIteam.insrOfrYmd2 = pIteam.insrOfrYmd.trim().length > 0 ? PSDateUtil.fn_DateFormat(pIteam.insrOfrYmd, 'yyyy-MM-dd') : '-'
                    pIteam.insrOfrYmd = pIteam.insrOfrYmd.trim().length > 0 ? PSDateUtil.fn_DateFormat(pIteam.insrOfrYmd, 'MM-dd') : '-'
                    
                    // 상품명 : 설계명에 삼성생명, 삼성 제거 처리
                    pIteam.mnPrdtNm = pIteam.mnPrdtNm.replace('삼성생명', '')
                    pIteam.mnPrdtNm = pIteam.mnPrdtNm.replace('삼성', '')
                    pIteam.mnPrdtNm = pIteam.mnPrdtNm.trim()

                    // 진행현황
                    pIteam.prcssStatus = '진행조회'

                    sortArray2.push(pIteam)
                  }) // t_rslt2.forEach 종료

                  if ( serviceName === 'S2' ) {
                    lv_Vm.orgTableData2 = lv_Vm.tableData2 = sortArray2
                  } else {
                    sortArray2.forEach(item => {
                      // lv_Vm.orgTableData2.push(item)
                      lv_Vm.tableData2.push(item)
                    })
                  }
                  lv_Vm.tableLength = lv_Vm.tableData2.length
                  lv_Vm.stopListTotCnt = response.body.totCnt
                  lv_Vm.fn_SetOrderByFields(lv_Vm.tableLength) // 정렬 컴포넌트 필드정의 건수 셋팅
                  // if (serviceName === 'S2') lv_Vm.fn_SetOrderByFields(lv_Vm.tableLength) // 정렬 컴포넌트 필드정의 건수 셋팅
                  // else lv_Vm.$emit('custnm-tableLength2', lv_Vm.tableLength, lv_Vm.sortFieldList2) // 더보기시 검색건수만 메인으로

                  // GA 매니저 대행 컨설턴트 변경시
                  if(!lv_Vm.$cmUtil.fn_GachangeTF()){
                    //모바일청약 정보 확인후 조회시 팝업창
                    if(!lv_Vm.$bizUtil.isEmpty(lv_Vm.pvuchIdData)){
                      let pParam = {vuchId : lv_Vm.pvuchIdData}
                      lv_Vm.fn_PrgPhseOpenPopup(pParam)
                      lv_Vm.pvuchIdData = ''
                    }
                  }

                  //ody 펼침추가
                  lv_Vm.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
                    for(let i = 0 ; i < lv_Vm.tableData2.length; i++){
                      lv_Vm.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
                    }
                  });
                  //ody 펼침추가

                } else {
                  if (serviceName === 'S2') lv_Vm.fn_TableStat('EPSC060') // 조회결과 없음 처리
                }
              }

              break
            
          }
          
        })
        .catch(function (error) {
          lv_Vm.fn_TableStat('EPSC060')
          lv_Vm.searchFlag = false
          window.vue.error(error)
        })
    },

  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/
  /******************************************************************************
  * Function명  : fn_SetOrderByFields
  * 설명        : 정렬 컴포넌트 필드정의 셋팅
  *               화면에서 소트 컨트롤 방식 셋팅 fn_SortChangeHandler 에서 처리
  * 
  ******************************************************************************/
  fn_SetOrderByFields (tableLength) {
      this.sortFieldList2 = [
         {field : 'insrOfrYmd2', label : '청약일'      , sort: 'DESC'}
        ,{field : 'mnContrNm' , label : '계약자'      , sort: 'ASC'}
        ,{field : 'insrdNm'   , label : '피보험자'    , sort: 'ASC'}
        ,{field : 'aisdNm'    , label : '종피보험자'  , sort: 'ASC'}
      ]
      this.$emit('custnm-tableLength2', tableLength, this.sortFieldList2) // 검색건수 메인으로
                  
  },
  /******************************************************************************
  * Function명  : fn_SortChangeHandler
  * 설명        : 정렬 이벤트 핸들러
  *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
  ******************************************************************************/
  fn_SortChangeHandler (pSortOrder){
    console.log('fn_SortChangeHandler !!!')

    let sortField = pSortOrder.field
    let sortOrderby = pSortOrder.sort //ASC or DESC

    console.log(pSortOrder)
    console.log('sortField = '+sortField+ ' , sortOrderby = '+sortOrderby)
    
    switch(sortField){
      case 'mnContrNm':  //String 형 sort
      case 'insrdNm':  //String 형 sort
      case 'aisdNm':  //String 형 sort
        PSCommUtil.fn_SortListValueString(sortOrderby, this.tableData2, sortField)
        break
      case 'insrOfrYmd2': // 날짜형 String 을 Date 형으로 변환 처리 해서 sort
        PSCommUtil.fn_SortListValueDateString(sortOrderby, this.tableData2, sortField, 'yyyy-MM-dd') //yyyy-MM-dd
        break
      //case 'codeVal': //수치형 String 을 Number로 변환 처리  해서 sort
      //case 'priceVal': //수치형 sort
      //  PSCommUtil.fn_SortListValueNumber(sortOrderby, this.tableData1, sortField)
      //  break;    
    }//switch

  },

  /******************************************************************************
  * Function명  : fn_SortReset
  * 설명        : 정렬 리셋 이벤트 핸들러
  ******************************************************************************/
  fn_SortReset(){
    console.log('fn_SortReset2 !!!')
    // 원본 되돌리기
    // this.tableData2 = []      
    // this.orgTableData2.forEach(item => {
    //   this.tableData2.push(Object.assign({},item))
    // });

    // this.$refs.sortFieldComp2.fn_Reset()
    this.$emit('sortReset2')
  },

  /******************************************************************************
   * Function명  : fn_CurrntDayPlusDateChk
   * 설명        : 현재날짜-10일까지 날짜정보 처리 -> 
                   공휴일을 제외한 워킹데이 처리할때 사용하면 됨
  *******************************************************************************/
  fn_currntDayPlusDateChk(cnt) {
    let dayCnt = 0
    let holyDayChkDate = ''
    let i = this.holyDayChkDateArry.length - 1
    for ( i; i > 0; i-- ) {
      if ( this.holyDayChkDateArry[i].holyCd === '00') {
        dayCnt++
      }
      if ( dayCnt === cnt ) {
        holyDayChkDate = this.holyDayChkDateArry[i].slrcYmYmd
        break
      }
    }
    return holyDayChkDate
  },

  /******************************************************************************
   * Function명  : fn_TableStat
   * 설명        : 데이터 없는 페이지 설정
   this.$t('ps')['EPSC012']
  *******************************************************************************/
  fn_TableStat (mesgTextCd,log =''){
    
    let mesgText = this.$t('ps')[mesgTextCd]
    console.log(">>>fn_TableStat mesgText : "+mesgText)
    this.mesgText = mesgText

    //this.$refs.nodataMesgBox.fn_SetMesgText(mesgText)
    //this.$refs.nodataMesgBox.mesgText = mesgText

  },

    /************************************************************************************************
     * Function명  : fn_PrgPhseOpenPopup
     * 설명        : 진행단계 팝업
     ************************************************************************************************/
    fn_PrgPhseOpenPopup: function (pData) {
      let lv_Vm = this
       
      this.rpblPopup = this.$bottomModal.open(MSPPS231P, {
        properties: {
          pParntSrnId: this.$options.screenId, // 부모 화면 ID          
          pRowPopupData : pData
        },
        listeners: { 
          // 대상팝업 onPopupCancel $emit 이벤트명 
          onPopupCancel : (rtnData) => {            
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기   
            
          },
          // 대상팝업 onPopupClose $emit 이벤트명 
          onPopupClose : (rtnData) => {            
             this.$bottomModal.close(this.rpblPopup) // 모달 닫기        
          }
        }
      })
    },

  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>