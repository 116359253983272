/*
 * 업무구분   : 공통
 * 화면 명    : MSPBC411D
 * 화면 설명  : 컨설턴트
 */
<template #collapsing>
  <!-- MSPBC411D -->
  <div class="consult-switch__wrap">
    <dl>
      <dt>컨설턴트</dt>
      <dd>
        <span>{{vCnsltInfo}}</span>
        <span>
          <mo-button size="small" class="line-btn__blue"  @click="fn_openMSPBC410P()">변경하기</mo-button>
        </span>
      </dd>
    </dl>
  </div>
  <!-- //MSPBC411D -->
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ***********************************************************************************/
import MSPBC410P from '@/ui/bc/MSPBC410P'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역
   * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
   * - screenId : 화면ID
   * - components : UI TAG 컴포넌트 정의
   ***********************************************************************************/
  name: 'MSPBC411D',
  screenId: 'MSPBC411D',
  components: { 
    'MSPBC410P' : MSPBC410P //매니저선택 팝업
  },
  /***********************************************************************************
   * 화면 UI Property 정의
   * - 파리미터 받는 변수 props 정의
   ***********************************************************************************/
  props: {
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
   * - UI 바인딩 및 스크립트 화면 전역변수 정의
   ***********************************************************************************/
  data() {
    return {
      eplyNo: '', // 컨설턴트ID
      vCnsltNm: '', // 컨설턴트명
      vCnsltInfo: this.getStore('userInfo').getters.getUserInfo.userNm + "(" + this.getStore('userInfo').getters.getUserInfo.userId + ")", //'김삼성(000000000)', // 컨설턴트정보
      // 검색어 필수체크
      // validateRuleCnsltInfo: [
      //   v => !!v.trim() || '컨설턴트는 필수선택 항목입니다.'
      // ]
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
   * - 각단계별 예약 함수에 맞게 스크립트 작성
   ***********************************************************************************/
  /** Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선 */
  beforeCreate () {

  },
  /** Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선 */
  created() {
    //컨설턴트 초기화
    this.getStore('cmStore').dispatch('GSS_EPLY_INFO', {})
  },
  /** Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선 */
  beforeMount () {

  },
  /** Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선 */
  mounted() {
    
  },
  /** Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수 */
  beforeUpdate () {

  },
  /** Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수 */
  updated () {

  },
  /** Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    
  },
  /** Step-8 인스턴스가 Remove 된 후 호출 */
  destroyed () {
    
  },
  /** Step-Etc1 KeepAlive 화면이 활성화 된 후 호출 - KeepAlive 화면은 떠나도 초기화 되지 않고 유지됨 */
  activated () {

  },
  /** Step-Etc2 KeepAlive 화면이 비활성화 된 후 호출 - KeepAlive 화면은 떠나도 초기화 되지 않고 유지됨 */
  deactivated () {
    
  },
  /***********************************************************************************
   * Computed 함수 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
   *   지정 함수는 반드시 리턴 하는 함수로 작성
   *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
   ***********************************************************************************/
  computed: {
    //변수가 변경되면 호출되는 Getter
    
  },
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
   ***********************************************************************************/
  watch: {
    //변수가 변경되면 호출되는 함수
    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
   ***********************************************************************************/
  methods: {
    /*******************************************************************************
     * Function명: fn_openMSPBC410P
     * 설명: 컨설턴트 선택 팝업 호출
     *******************************************************************************/
    fn_openMSPBC410P(){
      let lv_Vm = this
      this.popupBC410P = this.$bottomModal.open(MSPBC410P, {
        properties: {
        },
        listeners: {
          // 확인
          onPopupConfirm: (pData) => {
            console.log('팝업 파라미터>>>>', pData)
            
            lv_Vm.eplyNo = pData.eplyNo // 컨설턴트ID
            lv_Vm.gssEplyNm = pData.gssEplyNm // 컨설턴트명
            lv_Vm.vCnsltInfo = pData.gssEplyNm + "(" + pData.eplyNo + ")"
            
            this.getStore('cmStore').dispatch('GSS_EPLY_INFO', pData)
            lv_Vm.$emit('searchCallback', pData)
            lv_Vm.$bottomModal.close(lv_Vm.popupBC410P)
          },
          // 닫기
          onPopupClose: () => {
            console.log('팝업 닫기>>>>')
            lv_Vm.$bottomModal.close(lv_Vm.popupBC410P)
          }
        }
      },
      {
        properties : {noHeader : true}
      })
    },
    fn_resetCnsltInfo(){
      this.vCnsltInfo = this.getStore('userInfo').getters.getUserInfo.userNm + "(" + this.getStore('userInfo').getters.getUserInfo.userId + ")"
    }

  }
};
</script>