/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS230M
 * 화면 설명: 모바일청약현황(중단건,재처리)
 *            AS-IS TSSPS230D 전환 화면
 *            완료건/중단건 Division 분리 함     
 */
<template>
  <ur-page-container class="msp" title="모바일청약" :show-title="true" ref="page" type="subpage" :action-type="actionType" :topButton="true"
    @on-scroll-bottom="fn_ScrollBottomHandler" 
    @on-header-left-click="fn_HeaderBackBtnHandler">
    <!-- Content영역 -->
    <!-- MSPBC411D 컨설턴트영역 -->
    <MSPBC411D ref="page411D" v-if="vGssAuthYN === 'Y' && slctTabIdx === 1" @searchCallback="fn_SrchCallback"/>

    <template #frame-header-fixed>
      <mo-tab-box :defaultIdx="defaultIdxs" @tab-change="fn_TabChangeHandler" ref="tabbox"  class="ns-move-tab-box">
        <mo-tab-content :idx="1" label="중단" >        
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
            <!-- ASR240701057 / 모바일청약 중단건/진행조회 화면 개선 -->
            <!-- 텍스트 삽입 2024.08.07:mijin -->
          <ur-box-container direction="column" align-v="start" class="ns-info-area">
            <ur-box-container alignV="start" componentid="" direction="column" class="ml25">
              <ul class="terms-list-area--gray mt15">
                <li>유효기간이 지난건은 조회 불가(발행후 5영업일내)</li>
                <li>진행조회시, <span class="mo-icon-msg"></span>알림톡 발송 가능</li>
              </ul>
            </ur-box-container>
          </ur-box-container>
            <mo-list-item>
              <!-- <mo-checkbox  size="small" /> -->
              <div class="list-item__contents">
                <div class="list-item__contents__title mb3">
                  <span class="sum">총<span class="count">{{tableLength2}}</span>건</span>
                  <!-- 리스트 정렬 컴포넌트 -->
                  <PSListSortOpt2 ref='sortFieldComp2'
                                defaultLabel='정렬선택'
                                popupTitle="정렬선택"
                                itemValue="name" 
                                itemText="label"
                                :sortFieldList='sortFieldList2'
                                @sortChange='fn_SortHandler'/>
                  <!--/ 리스트 정렬 컴포넌트 -->        
                </div>
              </div>
            
            </mo-list-item>      
          </ur-box-container>
        </mo-tab-content>

        <mo-tab-content :idx="2" label="완료" >
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
            <mo-list-item>
              <!-- <mo-checkbox  size="small" /> -->
              <div class="list-item__contents">
                <div class="list-item__contents__title mb3">
                  <span class="sum">총<span class="count">{{tableLength1}}</span>건</span>
                  <!-- 리스트 정렬 컴포넌트 -->
                  <PSListSortOpt1 ref='sortFieldComp'
                                defaultLabel='정렬선택'
                                popupTitle="정렬선택"
                                itemValue="name" 
                                itemText="label"
                                :sortFieldList='sortFieldList1'
                                @sortChange='fn_SortHandler'/>
                  <!--/ 리스트 정렬 컴포넌트 -->            
                </div>
              </div>
            
            </mo-list-item>      
          </ur-box-container>   
        </mo-tab-content>      
      </mo-tab-box>
    </template>
    <!-- <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">      
      <ur-box-container direction="column" alignV="start" class="">
        <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand"> -->

          <MSPPS232D v-show="slctTabIdx === 1" :slctTabIdx="slctTabIdx" ref="tabIntrs" :srchOptData="srchOptDataIntrs" @custnm-tableLength2="fn_TableLength" @sortReset2="fn_SortReset2" :pvuchIdData="pvuchId" :vGssAuthYN="vGssAuthYN"/>
          <MSPPS231D v-show="slctTabIdx === 2" :slctTabIdx="slctTabIdx" ref="tabCmplt" :srchOptData="srchOptDataCmplt" @custnm-tableLength1="fn_TableLength" @sortReset="fn_SortReset"/>
<!-- 
        </ur-box-container>
      </ur-box-container>
    </ur-box-container> -->
    <!-- content 영역 -->
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

import PSMixinTabBoxPageScroll from '~/src/ui/ps/comm/PSMixinTabBoxPageScroll'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import MSPPS231D from '@/ui/ps/MSPPS231D'
import MSPPS232D from '@/ui/ps/MSPPS232D'
import MSPPS201P from '@/ui/ps/MSPPS201P'
import PSHeaderSubMenuPopup from '@/ui/ps/comp/PSHeaderSubMenuPopup'
import PSListSortOpt from '@/ui/ps/comp/PSListSortOpt'
import Screen from '~systems/mixin/screen'
import MSPBC411D from '@/ui/bc/MSPBC411D'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS230M', 
  screenId: 'MSPPS230M',
  mixins : [PSMixinTabBoxPageScroll, Screen],

  components: {
    'MSPPS201P' : MSPPS201P, //검색 키워드 입력 팝업
    'PSHeaderSubMenuPopup' : PSHeaderSubMenuPopup, // 서브메뉴 팝업
    'MSPPS231D' : MSPPS231D ,
    'MSPPS232D' : MSPPS232D ,
    'PSListSortOpt1' : PSListSortOpt,
    'PSListSortOpt2' : PSListSortOpt,
    'MSPBC411D' : MSPBC411D,
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/

  data () {
    return {
      vGssAuthYN: '', //ga매니저 권한    
      tableLength1 : 0,
      tableLength2 : 0,
      sortFieldList1 : [],
      sortFieldList2 : [],
      actionType : 'none',
      pvuchId : '', //모바일청약 정보확인후 중단건 띄울 팝업 vuchId
      defaultIdxs : 1,
      PSConstants : PSConstants, // 청약 상수
      srchOptDataIntrs : null, //중단건 검색옵션
      srchOptDataCmplt : null, //완료건 검색옵션
      slctTabIdx : 1 // 현재 선택된 탭 인덱스 값 1 : 완료건 2:중단건
      //_temp : null
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    this.srchOptDataIntrs = this.fn_GetSrchOptFormb() // 중단건 검색옵션
    this.srchOptDataCmplt = this.fn_GetSrchOptFormb() // 완료건 검색옵션

    //청약 팝업에서 넘어올경우 idx ,vuchId
    let rParams = this.$route.params
    if(rParams.tabIdx) this.defaultIdxs = Number(rParams.tabIdx)
    if(rParams.vuchId) this.pvuchId = rParams.vuchId
    
    this.actionType ='none' // 리셋
    this.actionType = PSCommUtil.fn_SetAppBarSearchMoreMenuRefresh(this.fn_HeaderSerchHander,this.fn_MoreMenuHandler,this.fn_RefreshHandler) // ASR240600301 / 모바일청약 현황 화면 새로고침 추가 메서드 변경

    //ga매니저 권한
    this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN
    
  },//created


  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    
    //PSMixinTabBoxPageScroll override
    this.fn_SetTabBoxPageScrollMixin (this.$refs.page,this.$refs.tabbox)

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)    
    
    this.$refs.tabbox.setIdx(this.defaultIdxs)

    //초기조회
    this.fn_TabChangeHandler(this.defaultIdxs)
  },//mounted
  
  activated() {
    //keepAlive : true 일때 GA매니저 가설 대행이면 조회 초기화
    let gssAuthYN = window.vue.getStore('cmStore').getters.getState.gssAuthYN
    if(gssAuthYN === 'Y' && this.slctTabIdx == 1){
      this.$refs.page411D.fn_resetCnsltInfo()
      this.tableLength2 = 0
      this.$refs.tabIntrs.fn_SearchEvent() 
    }
  },
 
  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {
  },//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
   /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    },

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : Header Back 타이틀 클릭 핸들러
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      // this.$router.go(-1)      
      this.$router.push({ name: 'MSPBC002M' })
    },// fn_HeaderBackBtnHandler

   /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
      console.log('MSPPS230M fn_ScrollBottomHandler !! this.slctTabIdx = '+this.slctTabIdx)
      let trgtTabComp = (this.slctTabIdx == 1)?  this.$refs.tabIntrs  : this.$refs.tabCmplt
      let trgtTabServiceName = (this.slctTabIdx == 1)?  'S2_N' : 'S1_N'
      let trgtSrchOpt = (this.slctTabIdx == 1)?  this.srchOptDataIntrs : this.srchOptDataCmplt //1:중단건 2:완료건

      //검색 키워드 
      if(!trgtSrchOpt.keyword) trgtTabComp.fn_ServiceData(trgtTabServiceName) 
    },

    /******************************************************************************
    * Function명  : fn_HeaderSerchHander
    * 설명        : Header 검색버튼 핸들러
    ******************************************************************************/
    fn_HeaderSerchHander() {
      this.pvuchId = '' //청약확인 vuchId 초기화
      
      let trgtSrchOpt = (this.slctTabIdx == 1)?  this.srchOptDataIntrs : this.srchOptDataCmplt //1:중단건 2:완료건
      let trgtTabComp = (this.slctTabIdx == 1)?  this.$refs.tabIntrs : this.$refs.tabCmplt 

      this.searchPopup = this.$bottomModal.open(MSPPS201P, {
        properties: {
          pParntSrnId      :  this.$options.screenId, // 부모 화면 ID
          pSearchKeyword   :  trgtSrchOpt.keyword, //검색 키워드 
          pSearchScVal     :  trgtSrchOpt.srchSc //검색 구분 (1:고객명,2:상품명,3:영수증번호)          
        },
        
        listeners: {

          // 대상팝업 onPopupSearch $emit 이벤트명 
          onPopupSearch: (rtnData) => {
            console.log(rtnData)

            this.$bottomModal.close(this.searchPopup)     // 모달 닫기

            this.$set(trgtSrchOpt,'srchSc',rtnData.searchScVal)
            this.$set(trgtSrchOpt,'keyword',rtnData.searchKeyword)
            this.$set(trgtSrchOpt,'chnlCustId', (rtnData.chnlCustId)? rtnData.chnlCustId : '' )
            
            trgtTabComp.fn_SearchEvent()
          }          
        }
      })

    },// fn_HeaderSerchHander

    fn_MoreMenuHandler(){
      console.log('fn_MoreMenuHandler!!!')
      //팝업 열기 
      let subMenuModalId = this.$bottomModal.open(PSHeaderSubMenuPopup, {
        properties: {          
          parentSrnId  : this.$options.screenId, // 부모 화면 ID
          popupTitle   : '청약설계 업무 더보기',                    
          subMenuList  : PSCommUtil.fn_GetOfrPlanMoreMenuList() //청약설계 보조메뉴 리스트
        },
        listeners: {
          popupEventClose : () => {
            this.$bottomModal.close(subMenuModalId) // 모달 닫기
          },
          popupEventRouterMove: (rtnData) => {//subMenuList에서 선택함 Item 리턴
            this.$bottomModal.close(subMenuModalId) // 모달 닫기
            this.isActivePopup = false
          },      
        }
      },{properties: {closeBtn: true}})
    },

 

    /******************************************************************************
    * Function명  : fn_TabChangeHandler
    * 설명        : tab box Change Handler                     
    ******************************************************************************/
    fn_TabChangeHandler(idx){
      this.slctTabIdx = idx
      //keepAlive : true 일때 GA매니저 가설 대행이면 조회 초기화
      let gssAuthYN = window.vue.getStore('cmStore').getters.getState.gssAuthYN
      if(gssAuthYN === 'Y' && this.slctTabIdx == 1){
        this.tableLength2 = 0
        this.$refs.tabIntrs.fn_SearchEvent() 
      }else{
        if(this.slctTabIdx == 1 && ! this.$refs.tabIntrs.isInitSrvcCall ) { //중단건 초기서비스 호출
          this.$refs.tabIntrs.fn_SearchEvent() 
        } else if(this.slctTabIdx == 2 && ! this.$refs.tabCmplt.isInitSrvcCall ){//완료건 초기서비스 호출
          this.$refs.tabCmplt.fn_SearchEvent() 
        }
      }
    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/


  
  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/
  /******************************************************************************
  * Function명  : fn_GetSrcOpt
  * 설명        : 검색옵션 format 리턴              
  ******************************************************************************/
  fn_GetSrchOptFormb(){
    return { srchSc :'1' , //검색 구분 (1:고객명,2:상품명,3:영수증번호)
             keyword : '', //검색 키워드 
             chnlCustId : '' // 고객명으로 검색시 선택한 채널 고객id
           }
  },

  /******************************************************************************
  * Function명  : fn_TableLength
  * 설명        : fn_TableLength 리턴              
  ******************************************************************************/
  fn_TableLength(length, fieldList){
    if (this.slctTabIdx == 1) {
      this.tableLength2 = length
      this.sortFieldList2 = fieldList
    } else {
      this.tableLength1 = length
      this.sortFieldList1 = fieldList
    }
  },

  fn_SortHandler (pSortOrder){
    if (this.slctTabIdx == 1) {
      this.$refs.tabIntrs.fn_SortChangeHandler(pSortOrder)
    } else {
      this.$refs.tabCmplt.fn_SortChangeHandler(pSortOrder)
    }
  },

  fn_SortReset2(){
   this.$refs.sortFieldComp2.fn_Reset()
  },
  fn_SortReset(){
   this.$refs.sortFieldComp.fn_Reset()
  },
  fn_RefreshHandler(){ // ASR240600301 / 모바일청약 현황 화면 새로고침 추가
    if(this.slctTabIdx == 1) { //중단건
          this.$refs.tabIntrs.fn_ServiceCall('S')
    } else if(this.slctTabIdx == 2){//완료건
          this.$refs.tabCmplt.fn_ServiceCall('S')
    }
  },
  /*********************************************************
  * @function fn_SrchCallback
  * @name     콜백함수
  * @notice   컨설턴트 콜백함수
  *********************************************************/
  fn_SrchCallback(pData) {
    console.log('콜백 파라미터>>>>', pData)
    this.vEplyNo = pData.eplyNo // 컨설턴트ID
    this.vGssEplyNm = pData.gssEplyNm // 컨설턴트명

    console.log("변경한 컨설턴트 gssEplyInfo :::::::::::" + JSON.stringify(this.getStore('cmStore').getters.getState.gssEplyInfo))
    this.tableLength2 = 0
    this.$refs.tabIntrs.fn_SearchEvent() 
  },


    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>